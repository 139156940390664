import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'

import TextBox from '@atoms/TextBox'
import HorWrap from '@atoms/HorWrap'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import BlogCard from '@molecules/BlogCard'
import BlogGrid from '@molecules/BlogGrid'

import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

const BlogCategoryPage = ({ data, transitionStatus }) => {
  const categoryData = data.blogCategory
  const postsData = data.blogCategoryPosts.edges
  return (
    <Main seo={categoryData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Blog" center>
          <h2>{categoryData.name}</h2>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        {postsData.length !== 0 && (
          <BlogGrid>
            {postsData.map(blogPost => (
              <BlogCard data={blogPost.node} />
            ))}
          </BlogGrid>
        )}
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query BlogCategoryBySlug($id: String, $slug: String) {
    blogCategory: datoCmsBlogCategory(id: { eq: $id }) {
      id
      slug
      name
      seoMetaTags {
        tags
      }
    }
    blogCategoryPosts: allDatoCmsBlogPost(
      sort: { order: DESC, fields: meta___publishedAt }
      limit: 30
      filter: {
        meta: { status: { eq: "published" } }
        category: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          id
          slug
          cover {
            alt
            url
          }
          category {
            name
            slug
          }
          title
          shortcut
          lead
          meta {
            publishedAt(formatString: "DD/MM/YY")
            status
          }
          content {
            blocks
            links
            value
          }
        }
      }
    }
  }
`

export default BlogCategoryPage
